<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <v-tabs color="black" v-model="tabs" show-arrows>
        <v-tab class="fonte-bold">Tudo</v-tab>
        <v-tab class="fonte-bold">Receb. Bonificação</v-tab>
        <v-tab class="fonte-bold">Ped. Saque</v-tab>
        <v-tab class="fonte-bold">Depósito</v-tab>
        <v-tab class="fonte-bold">Compras</v-tab>
        <v-tab class="fonte-bold">Transferências</v-tab>
      </v-tabs>
      <v-flex xs12>
        <div class="expande-horizontal">
          <v-list style="min-width: 100%;" class="pa-0 ma-0" three-line>
            <template v-for="(item, index) in getTransactions">
              <v-list-item :key="item._id">
                <v-list-item-content>
                  <v-list-item-title class="fonte-bold">
                    {{ $helper.formataSaldo(item.value) }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="fonte">
                    <v-chip x-small style="border-radius: 6px;" :color="getStatus(item.current_status).color">
                      <span class="white--text fonte-bold">{{ getStatus(item.current_status).text }}</span>
                    </v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.description }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="font-weight-light"
                    style="font-size: 8pt;"
                  >
                    {{
                      $moment(item.created_at)
                        .locale("pt-br")
                        .format("llll")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="index"></v-divider>
            </template>
          </v-list>
        </div>
      </v-flex>

      <Empty v-if="getTransactions.length === 0"></Empty>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tabs: 0
    };
  },
  computed: {
    ...mapGetters(["getTransactions"]),
    filteredTransactions() {
      const type_filters = {
        0: {},
        1: {},
      }
      let filters = {};

    }
  },
  methods: {
    ...mapActions(["listTransactions"]),
    getStatus(status) {
      switch (status) {
        case "waiting":
          return {
            text: "Aguardando",
            color: "orange"
          };
        case "active":
          return {
            text: "Disponível",
            color: "green"
          };
        case "requested":
          return {
            text: "Não consolidada",
            color: "red"
          };
        default:
          return {
            text: "Outros",
            color: "black"
          };
      }
    },
  },
  created() {
    this.listTransactions();
  }
};
</script>
